class ApiClient {
    baseUrl = "/api/v1/";

    constructor() {
    }

    get(url, query = null) {
        if (query) {
            var u = new URL('https://example.com/');
            for (var k in query) {
                u.searchParams.append(k, query[k]);
            }
            query = u.searchParams.toString();
        }
        if (query) {
            url = url + '?' + query;
        }
        return fetch(this.baseUrl + url);
    }
    
    post(url, data) {
        return fetch(this.baseUrl + url, {
            method: "POST",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
    }
    
    delete(url, data) {
        return fetch(this.baseUrl + url, {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        });
    }
    
    async getAttractions() {
        var res = await this.get('attractions');
        var json = await res.json();
        return json;
    }

    async getAttractionMap(attractionId, resourceTypeId, reservationId) {
        var res = await this.get(`attraction/${attractionId}/map?resourceTypeId=${resourceTypeId}&reservationId=${reservationId}`);
        var json = await res.json();
        return json;
    }

    async getAttraction(attractionId) {
        var res = await this.get('attraction/' + attractionId);
        return await res.json();
    }

    async createReservation(attractionId, name, emailAddress, phoneNumber) {
        var res = await this.post('reservations', {
            attractionId,
            name,
            emailAddress,
            phoneNumber,
        });
        return await res.json();
    }

    async updateReservation(reservationId, riderName, emailAddress, phoneNumber) {
        var res = await this.post(`reservation/${reservationId}`, {
            riderName,
            emailAddress,
            phoneNumber,
        });
        var json = await res.json();
        if (res.status >= 400) {
            throw json;
        }
        return json;
    }

    async confirmReservation(reservationId, token) {
        var res = await this.post(`reservation/${reservationId}/confirm`, {
            token
        });
        var json = await res.json();
        if (res.status >= 400) {
            throw json;
        }
        return json;
    }

    async getReservation(reservationId) {
        var res = await this.get('reservation/' + reservationId);
        return await res.json();
    }

    async addResources(reservationId, resourceIds, removeOthers = false, resourceTypeId = null) {
        var res = await this.post(`reservation/${reservationId}/resources`, {
            resourceTypeId,
            replace: removeOthers,
            resources: resourceIds.reduce((x,y) => { x[y] = []; return x; }, {}),
        });
        return await res.json();
    }

    async removeResources(reservationId, resourceIds) {
        await this.delete(`reservation/${reservationId}/resources`, {
            resourceIds
        });
    }

    async updateResourceOptions(reservationId, resourceId, options) {
        await this.post(`reservation/${reservationId}/resource/${resourceId}`, options);
    }

    async getStripeAppSettings(id) {        
        var res = await this.get(`app/settings/${id}`);        
        var temp = await res.json();        
        return temp.publishableKey !== null ? temp.publishableKey : "pk_XXXXXXXXXXXXXX";
    }
}

var apiClient = new ApiClient();

export default apiClient;