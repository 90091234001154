import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'

import 'jquery';
import 'bootstrap';
import './assets/style.scss';
import { BootstrapVue } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import UniqueId from 'vue-unique-id';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTrashAlt, faPlusCircle, faCreditCard } from '@fortawesome/pro-regular-svg-icons'
import { faChevronRight, faCheck } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// import apiClient from './apiClients/reflectionArenaClient.js';

library.add(faTrashAlt, faPlusCircle, faCreditCard, faChevronRight, faCheck);

Vue.component('font-awesome-icon', FontAwesomeIcon)

// Pages (for router)
import AttractionList from "./pages/AttractionList.vue";
import AttractionRegister from "./pages/AttractionRegister.vue";

// Dialogs
import ResourceDetails from "./components/ResourceDetails.vue";

Vue.config.productionTip = false

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(UniqueId);

Vue.component("resource-details", ResourceDetails);

Vue.mixin({
    methods: {
        formatCurrency(amount) {
            return (+amount === 0) ? '-' : this.$currencyFormatter.format(amount);
        },
        formatDateTime(date) {
            return this.$dateTimeFormatter.format(new Date(date + 'Z'));
        },
    },
});

const routes = [
    { path: '/', redirect: '/attractions', },
    { path: '/attractions', component: AttractionList, },
    { path: '/attraction/:attractionId/register', component: AttractionRegister, },
    { path: '/reservation/:reservationId', component: AttractionRegister, },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

Vue.prototype.$currencyFormatter = Intl.NumberFormat('en-US', { currency: 'USD', style: 'currency', minimumFractionDigits: 2, maximumFractionDigits: 2 });
Vue.prototype.$dateFormatter = Intl.DateTimeFormat('en-US');
Vue.prototype.$dateTimeFormatter = Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', timeZone: 'America/Chicago' });

const store = new Vuex.Store({
    state: {
        settings: {
            name: 'My Horse Connect',
            publishableKey: null,
        },
    },
    mutations: {
        // setAppSettings(state, settings) {
        //     state.settings = settings;
        // },
    },
});

async function launch() {
    // var appConfig = await apiClient.getAppSettings();
    // if (appConfig) {
    //     store.commit('setAppSettings', appConfig);
    // }
    new Vue({
        render: h => h(App),
        router,
        store,
    }).$mount('#app')
}

launch();
