<template>
    <div class="form-group row">
        <label class="col col-form-label col-md-2 text-md-right">{{resourceTypePlural}}:</label>
        <div class="col-xl-5 col-lg-6 col-md-8 col-xs">
            <div v-for="(item) in this.resources" :key="item.id" class="mb-1">
                <div class="widget d-flex" :class="[makeClass('widget-', item), item.isValid ? '' : 'was-validated is-server-error']">
                    <button type="button" class="btn widget-item widget-item-label" :disabled="disabled" :class="makeClass('btn-', item)">{{item.resource.name}}</button>
                    <div class="widget-item text-left flex-grow-1">
                        <div class="d-flex" v-for="(option, optIndex) in item.options" :key="optIndex">
                            <div class="flex-grow-1 text-left">
                                <label>
                                    <input type="checkbox" v-if="option.type === 'checkbox'" :checked="option.value === 'true'" @change="updateCheckboxResource(item.resource.id, option.id, $event.target.checked)" :disabled="disabled" />
                                    {{option.label}}
                                    <input type="number" v-if="option.type === 'numeric'" :value="option.value" @change="updateNumericResource(item.resource.id, option.id, $event.target.value)" :disabled="disabled" />
                                </label>
                            </div>
                            <div class="flex-grow-1 text-right">{{formatCurrency(option.price)}}</div>
                        </div>
                    </div>
                    <button v-if="!disabled" type="button" class="btn widget-item" :class="makeClass('btn-', item)" @click="() => removeResource(item)"><font-awesome-icon :icon="['far', 'trash-alt']" /></button>
                </div>
                <div class="server-error-feedback">{{resourceTypeSingular}} {{item.resource.name}} is no longer available. Please remove it and select another.</div>
            </div>
            <div v-if="this.resources.length === 0" class="col-form-label text-left" style="font-style: italic">Empty</div>
            <button v-if="!disabled" type="button" class="btn btn-secondary btn-sm" @click="() => showDialog = true">
                <font-awesome-icon :icon="['far', 'plus-circle']" />
                Add {{resourceTypeSingular}}
            </button>
        </div>
        <select-resources
            :visible="showDialog"
            :attractionId="attractionId"
            :reservationId="reservationId"
            :resourceTypeId="resourceTypeId"
            :facilityTypeSingular="facilityTypeSingular"
            :resourceTypeSingular="resourceTypeSingular"
            :resourceTypePlural="resourceTypePlural"
            @close="() => showDialog = false"
            @addResources="addResources"
            />
    </div>
</template>

<script>
import SelectResources from "../dialogs/SelectResources.vue";
import apiClient from "../apiClients/reflectionArenaClient";

export default {
    name: "ResourceWidgetList",
    components: {
        SelectResources,
        // ResourceWidget,
    },
    props: {
        resources: {
            type: Array,
            default: () => [],
        },
        // component: {
        //     default: () => ResourceWidget,
        // },
        attractionId: String,
        resourceTypeId: String,
        reservationId: String,
        facilityTypeSingular: {
            type: String,
            default: () => 'Facility',
        },
        resourceTypeSingular:  {
            type: String,
            default: () => 'Resource',
        },
        resourceTypePlural:  {
            type: String,
            default: () => 'Resources',
        },
        disabled: Boolean,
    },
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        makeClass(prefix, item) {
            var color = !item.isValid
                ? 'danger'
                : this.disabled
                    ? 'disabled'
                    : 'primary';
            return `${prefix}${color}`;
        },
        async refresh() {
        },
        async removeResource(item) {
            await apiClient.removeResources(this.reservationId, [item.resource.id]);
            this.$emit('refresh');
        },
        async addResources(items) {
            await apiClient.addResources(this.reservationId, items.map(x => x.id));
            this.$emit('refresh');
        },
        async updateCheckboxResource(resourceId, optionId, value) {
            await apiClient.updateResourceOptions(this.reservationId, resourceId, {[optionId]: value});
            this.$emit('refresh');
        },
        async updateNumericResource(resourceId, optionId, value) {
            await apiClient.updateResourceOptions(this.reservationId, resourceId, {[optionId]: value});
            this.$emit('refresh');
        },
    },
}
</script>