<template>
    <div :class="{ 'form-group': true, row: true, 'was-validated': showValidation }">
        <label :for="uid" class="col col-form-label col-2 text-right">{{label}}:</label>
        <div class="col input-container">
            <input ref="input" :type="type" :class="['form-control', validationStatus ? `is-${validationStatus}` : null]" :id="uid" :placeholder="placeholder" :value="formattedValue" @input="handleInput" @change="handleChange" :pattern="pattern" v-bind="$attrs" />
            <div class="invalid-feedback">{{validationMessage}}</div>
            <div class="server-error-feedback">We're sorry, something went wrong while saving.</div>
            <div class="persisted-feedback">Saved!</div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TextEditControl",
    inheritAttrs: false,
    props: {
        value: String,
        label: String,
        placeholder: String,
        updateFunction: Function,
        type: {
            type: String,
            default: 'text',
        },
        pattern: {
            type: String,
        },
        fieldFormatters: {
            default: () => [],
        },
        validationMessage: {
            type: String,
            default: 'Specify a valid value.',
        },
    },
    methods: {
        validate() {
            this.showValidation = true;
            return this.$refs.input.matches(':valid');
        },
        async handleChange() {
            this.$emit('input', this.textValue, this);
        },
        handleInput(evt) {
            this.textValue = this.formatValue(evt.target.value);
            this.$forceUpdate();
        },
        formatValue(value) {
            for (var formatter of this.fieldFormatters) {
                var m = formatter.regex ? value.match(formatter.regex) : value;
                if (m !== null) {
                    value = formatter.replacer(m);
                }
            }
            return value;
        },
        showStatus(status, dismissAfter = 1000) {
            this.showValidation = false;
            window.clearTimeout(this.validationStatusTimer);
            this.validationStatus = status;
            if (dismissAfter > 0) {
                this.validationStatusTimer = window.setTimeout(() => {
                    this.validationStatus = null;
                    this.showValidation = false;
                }, dismissAfter);
            }
        },
    },
    computed: {
        formattedValue: {
            get() { return this.formatValue(this.textValue); },
            set(value) { this.textValue = this.formatValue(value); },
        },
        statusIconStyle() {
            var styles = ['input-status'];
            if (this.statusIcon) {
                styles.push(this.statusIcon.style);
                if (this.statusIcon.visible) {
                    styles.push('input-status-visible');
                }
            }
            return styles.join(' ');
        },
    },
    beforeMount() {
        this.textValue = this.value;
    },
    data: () => ({
        showValidation: false,
        validationStatus: null,
        validationStatusTimer: null,
        textValue: "",
        statusIcon: {
            visible: false,
            style: 'bg-success',
            icon: 'check',
        },
    }),
    watch: {
        value(newVal) {
            this.textValue = newVal;
        },
    },
}
</script>