<script>
import TextEditControl from './TextEditControl.vue';

export default {
    name: 'EmailEditControl',
    extends: TextEditControl,
    props: {
        type: {
            default: 'email',
        },
        placeholder: {
            default: 'john@example.com',
        },
        pattern: {
            type: String,
            default: '.+@([^\\.]+\\.)+[^\\.]+',
        },
        validationMessage: {
            default: 'Specify a valid email address.',
        },
    },
}
</script>