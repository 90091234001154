<template>
    <div v-if="attraction">
        <div class="row mb-2">
            <div class="col d-flex">
                <span class="attraction-title">{{attraction.attractionName}}</span>
                <span class="attraction-date flex-grow-1 ml-4">{{formatDateRange(attraction.startDate, attraction.endDate)}}</span>
            </div>
        </div>
        <div v-if="!attraction.isActive && !$route.params.reservationId">
            <div class="row mb-3">
                <div class="col">This event is no longer accepting reservations.</div>
            </div>
        </div>
        <form class="needs-validation" novalidate v-if="attraction.isActive || $route.params.reservationId" ref="form" @submit="processValidation">
            <div class="row mb-3">
                <div class="col" v-if="!isPaid">Please fill out the following form to reserve your RV hookups and barn stalls for this event.</div>
                <div class="col" v-if="isPaid">This reservation has been confirmed and can no longer be modified online.</div>
            </div>
            <text-edit-control label="Name" placeholder="John Doe" v-model="reservation.riderName" @input="handleChange" data-lpignore="true" required validationMessage="You must provide a name for this reservation." :disabled="isPaid || busy" />
            <email-edit-control label="Email Address" v-model="reservation.emailAddress" @input="handleChange" data-lpignore="true" required :disabled="isPaid || busy || $route.params.reservationId" />
            <phone-edit-control label="Phone" v-model="reservation.phoneNumber" @input="handleChange" required data-lpignore="true" :disabled="isPaid || busy" />
            <resource-widget-list v-if="reservation.id" @refresh="refresh" :resources="barnStalls" :attractionId="attraction.id" :reservationId="reservation.id" resourceTypeId="eebc1d61-b344-486d-a442-4c234c77af8f" facilityTypeSingular="Barn" resourceTypeSingular="Stall" resourceTypePlural="Stalls" :disabled="isPaid || busy" />
            <resource-widget-list v-if="reservation.id" @refresh="refresh" :resources="rvHookups" :attractionId="attraction.id" :reservationId="reservation.id" resourceTypeId="6D67D146-07A4-4DB3-9647-EC1F04FE6439" facilityTypeSingular="Parking Lot" resourceTypeSingular="RV Hookup" resourceTypePlural="RV Hookups" :disabled="isPaid || busy" />
            <div class="form-group" v-if="$route.params.attractionId">
                <div class="col">
                    <button type="submit" class="btn btn-primary">
                        Save &amp; Continue
                        <font-awesome-icon :icon="['fas', 'chevron-right']" />
                    </button>
                </div>
            </div>
            <div class="row" v-if="$route.params.reservationId && !reservation.resources.length">
                <div class="col text-center">
                    <hr />
                    <em>You must add at least one RV Spot or Barn Stall to proceed.</em>
                </div>
            </div>
            <div v-if="$route.params.reservationId && (reservation.resources.length)">
                <hr />
                <div class="form-group row">
                    <label class="col col-form-label col-2"></label>
                    <div class="col-md-6 text-right">
                        <div class="row">
                            <div class="col text-right">
                                <div class="row">
                                    <div class="col">Processing Fee:</div>
                                    <div class="col-3">{{formatCurrency(reservation.processingFee)}}</div>
                                </div>
                                <div class="row">
                                    <div class="col">Subtotal:</div>
                                    <div class="col-3">{{formatCurrency(reservation.subTotal)}}</div>
                                </div>
                                <div class="row">
                                    <div class="col">Credit Card Fee ({{(reservation.creditCardFeePercent * 100).toFixed(5).replace(/0+$/,'').replace(/\.$/, '')}}%):</div>
                                    <div class="col-3">{{formatCurrency(reservation.creditCardFeeAmount)}}</div>
                                </div>
                                <div class="row" :style="!isPaid ? { fontWeight: 'bold' } : null">
                                    <div class="col">Total:</div>
                                    <div class="col-3">{{formatCurrency(reservation.total)}}</div>
                                </div>
                                <div class="row text-success" style="font-weight: bold" v-if="isPaid">
                                    <div class="col">Total Paid:</div>
                                    <div class="col-3">{{formatCurrency(reservation.total)}}</div>
                                </div>
                            </div>
                            <div v-if="!isPaid" style="min-width: 47px;" />
                        </div>
                        <div class="row">
                            <div class="col" v-if="!isPaid">
                                <div class="row py-4" style="font-weight: bold">
                                    <div class="col text-center">Reservations are not final until submitted.</div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                    <stripe-element-card :pk="publishableKey" ref="elementRef" @token="handleToken" @error="handleError" />
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <button type="submit" class="btn btn-primary" :disabled="busy">
                                        <font-awesome-icon :icon="['far', 'credit-card']" v-if="!busy"/>
                                        <div class="spinner-border spinner-border-sm" role="status" v-if="busy">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        {{busy ? 'Submitting Reservation...' : ' Submit Reservation'}}
                                    </button>
                                </div>
                            </div>
                            <div class="col" v-if="isPaid">
                                <div class="row py-4">
                                    <div class="col text-center">
                                        <div class="alert alert-success">
                                            <strong>Your payment has been received, and your reservation is confirmed!</strong><br />
                                            <small class="text-muted">
                                                Paid on {{formatDateTime(reservation.paidOn)}}. Confirmation number: <tt>{{reservation.transactionNumber}}</tt>
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <hr />
                    {{attraction.disclaimer}}
                </div>
            </div>
            <select-resources :visible="resourceSelectInfo.visible" @close="x => resourceSelectInfo.visible = false" :attractionId="attraction.id" :reservationId="reservation.id" :resourceTypeId="resourceSelectInfo.resourceTypeId" :facilityTypeSingular="resourceSelectInfo.facilityTypeSingular" :resourceTypeSingular="resourceSelectInfo.resourceTypeSingular" :resourceTypePlural="resourceSelectInfo.resourceTypePlural" />
        </form>
    </div>
</template>

<script>
import util from "../util.js";
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import SelectResources from "../dialogs/SelectResources.vue";
import ResourceWidgetList from "../components/ResourceWidgetList";
import TextEditControl from "../components/TextEditControl";
import PhoneEditControl from '../components/PhoneEditControl.vue';
import EmailEditControl from '../components/EmailEditControl.vue';

import apiClient from "../apiClients/reflectionArenaClient";

const sortBySortOrder = (a, b) => (a.resource.sortOrder > b.resource.sortOrder)
    ? 1
    : (b.resource.sortOrder > a.resource.sortOrder)
        ? -1
        : 0;

export default {
    name: "AttractionRegister",
    components: {
        SelectResources,
        ResourceWidgetList,
        TextEditControl,
        PhoneEditControl,
        EmailEditControl,
        StripeElementCard,
    },
    props: {
        attractionId: String,
        reservationId: String,
    },
    async created() {
        this.refresh();
    },
    data: () => ({
        busy: false,
        resourceSelectInfo: {
            visible: false,
            resourceTypeId: "",
            facilityTypeSingular: "",
            resourceTypeSingular: "",
            resourceTypePlural: "",
        },
        selectRvSpotVisible: false,
        selectBarnStallVisible: false,
        reservation: {
            "id": "",
            "riderName": "",
            "emailAddress": "",
            "phoneNumber": "",
            "resources": [],
        },
        attraction: null,
        rvHookups: [],
        barnStalls: [],
        publishableKey: "pk_XXXXXXXXXXXXXX"
    }),
    computed: {
        isPaid() { 
            return this.reservation && this.reservation.isPaid;
        },
    },
    methods: {
        async handleToken(token) {
            try {
                await apiClient.confirmReservation(this.reservation.id, token.id);
            }
            catch {
                //
            }
            finally {
                this.busy = false;
                this.$refs.elementRef.update({
                    disabled: false,
                });
            }
            this.refresh();
        },
        async handleError() {
            this.busy = false;
            this.$refs.elementRef.update({
                disabled: false,
            });
        },
        async processValidation(event) {
            event.preventDefault();
            event.stopPropagation();
            if (this.$route.params.reservationId) {
                // Capture the card information
                this.busy = true;
                this.$refs.elementRef.update({
                    disabled: true,
                });
                this.$refs.elementRef.submit();
                return;
            }

            // we need to create the draft reservation
            if (this.$refs['form'].checkValidity()) {
                // TODO: handle failure to create reservation
                await this.createReservation();
                this.$refs['form'].classList.remove('was-validated');
                return;
            }

            // Can't create the draft reservation!
            this.$refs['form'].classList.add('was-validated');
        },
        formatDateRange: util.formatDateRange,
        async createReservation() {
            var res = await apiClient.createReservation(this.$route.params.attractionId, this.reservation.riderName, this.reservation.emailAddress, this.reservation.phoneNumber);
            this.reservation.id = res.id;
            this.$router.replace(`/reservation/${res.id}`);
            await this.refresh();
        },
        async refresh() {
            if (this.$route.params.attractionId) {
                this.attraction = await apiClient.getAttraction(this.$route.params.attractionId);
                this.publishableKey = await apiClient.getStripeAppSettings(this.$route.params.attractionId);                
            }
            else if (this.$route.params.reservationId) {
                var res = await apiClient.getReservation(this.$route.params.reservationId);
                this.publishableKey = await apiClient.getStripeAppSettings(this.$route.params.reservationId);
                this.reservation = res;
                this.attraction = res.attraction;
                this.rvHookups = res.resources.filter(x => x.resource.type.id.toLowerCase() === "6d67d146-07a4-4db3-9647-ec1f04fe6439").sort(sortBySortOrder);
                this.barnStalls = res.resources.filter(x => x.resource.type.id.toLowerCase() === "eebc1d61-b344-486d-a442-4c234c77af8f").sort(sortBySortOrder);
            }
        },
        async handleChange(event, field) {
            if (!this.reservation.id) {
                return;
            }
            console.log("handle change", event, field);
            if (!field.validate()) {
                return;
            }
            try {
                await apiClient.updateReservation(this.reservation.id, this.reservation.riderName, this.reservation.emailAddress, this.reservation.phoneNumber);
                field.showStatus('persisted');
            }
            catch {
                field.showStatus('server-error', 0);
            }
        }
    }
}
</script>

<style scoped>
.attraction-title {
    font-size: 1.8rem;
    font-weight: bold;
}

.attraction-date {
    font-size: 1.8rem;
    font-style: italic;
    opacity: 0.5;
}
</style>