import { DateTime } from "luxon";

export default {
    formatDateRange(begin, end) {
        var startDate = DateTime.fromISO(begin);
        var endDate = DateTime.fromISO(end);
        if (startDate.year !== endDate.year) {
            return `${startDate.toFormat("MMMM d, yyyy")} - ${endDate.toFormat("MMMM d, yyyy")}`;
        }
        if (startDate.month !== endDate.month) {
            return `${startDate.toFormat("MMMM d")} - ${endDate.toFormat("MMMM d, yyyy")}`;
        }
        if (startDate.day !== endDate.day) {
            return `${startDate.toFormat("MMMM d")}-${endDate.toFormat("d, yyyy")}`;
        }
        return startDate.toFormat("MMMM d, yyyy");
    },
};