<template>
    <div>
        <div class="row mb-2">
            <div class="col text-center" v-if="attractions.length > 0">Upcoming Events</div>
            <div class="col text-center" v-if="attractions.length === 0">
                <strong>No Upcoming Events.</strong><br />
                Check back soon for more!
            </div>
        </div>
        <div class="row justify-content-md-center" v-for="(attraction, idx) in attractions" :key="idx">
            <div class="col text-center mb-2">
                <router-link :to="`/attraction/${attraction.id}/register`" tag="button" type="button" class="btn btn-primary w-100 d-flex text-left">
                    <span class="flex-grow-1 d-flex flex-column">
                        <span class="flex-grow-1 d-flex">
                            <span class="attraction-title flex-grow-1">{{attraction.attractionName}} @ {{attraction.venue.name}}</span>
                            <span class="attraction-date">{{formatDateRange(attraction.startDate, attraction.endDate)}}</span>
                        </span>
                        <span class="attraction-description mt-2">{{attraction.description}}</span>
                    </span>
                    <span class="ml-3 mr-1 align-self-center">
                        <font-awesome-icon :icon="['fas', 'chevron-right']" size="2x" />&nbsp;
                    </span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import util from "../util.js";
import apiClient from "../apiClients/reflectionArenaClient";

export default {
    name: "AttractionList",
    async created() {
        this.attractions = await apiClient.getAttractions();
    },
    data() {
        return {
            'attractions': [],
        };
    },
    methods: {
        formatDateRange: util.formatDateRange,
    },
}
</script>

<style scoped>
.attraction-title {
    font-weight: bold;
}

.attraction-date {
    font-style: italic;
}

.attraction-description {
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>