<script>
import TextEditControl from './TextEditControl.vue';

export default {
    name: 'PhoneEditControl',
    extends: TextEditControl,
    props: {
        type: {
            default: 'tel',
        },
        placeholder: {
            default: '(555) 867-5309',
        },
        pattern: {
            default: '^\\(\\d{3}\\) \\d{3}-\\d{4}( x\\d+)?',
        },
        validationMessage: {
            default: 'Specify a valid phone number.',
        },
        fieldFormatters: {
            default: () => [
                {
                    replacer: m => m.replace(/[^0-9]/g, ''),
                },
                {
                    regex: /^(\d{3})(\d{1,3})$/,
                    replacer: (m) => `(${m[1]}) ${m[2]}`,
                },
                {
                    regex: /^(\d{3})(\d{3})(\d{0,4})$/,
                    replacer: (m) => `(${m[1]}) ${m[2]}-${m[3]}`,
                },
                {
                    regex: /^(\d{3})(\d{3})(\d{4})(\d{1,})$/,
                    replacer: (m) => `(${m[1]}) ${m[2]}-${m[3]} x${m[4]}`,
                },
            ],
        },
    },
}
</script>