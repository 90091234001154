<template>
    <div class="container-fluid">
        <div class="row position-sticky top-0">
            <Header />
        </div>
        <div class="container" style="margin-top: 20px;" >
            <slot></slot>
        </div>
    </div>
</template>

<script>
import Header from "./Header";

export default {
    name: "Layout",
    components: {
        Header,
    }
}
</script>