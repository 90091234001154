<template>
    <b-modal ref="modal" id="resource-details">
        <template slot="modal-header">
            <h5 class="modal-title">RV Hookup #{{focusedSlot.number}}</h5>
            <button type="button" class="close" @click="hideDetails" aria-label="Close">&times;</button>
        </template>
        <div class="row">
            <div class="col-4 text-end col-form-label">Occupant:</div>
            <div class="col-6">
                <input class="form-control" type="text" v-model="focusedSlot.name" />
            </div>
        </div>
        <template slot="modal-footer">
            <button type="button" class="btn btn-secondary" @click="hideDetails">Cancel</button>
            <button type="button" class="btn btn-primary" @click="saveSlot">Save</button>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: "ResourceDetails",
    components: {
    },
    props: {
        'visible': Boolean,
        'focusedSlot': {
            type: Object,
            default: () => { return {
                number: 0,
                name: "",
            }},
        },
    },
    watch: {
        visible(newVal, oldVal) {
            if (!newVal && oldVal) {
                // hide
                this.$refs['modal'].hide();
                console.log("should hide");
            }
            else if (newVal && !oldVal) {
                // show
                this.$refs['modal'].show();
                console.log("should show");
            }
        },
    },
    methods: {
        saveSlot() {
            var slot = this.focusedSlot.slot;
            var name = (this.focusedSlot.name || '').trim();
            if (name === '') {
                slot.status = 'available';
                slot.label = '';
                slot.name = '';
            }
            else {
            var parts = name.split(' ');
            var last = parts.pop();
            slot.name = name;
            slot.label = parts.map(x => x[0]).join('') + ' ' + last;
            slot.status = 'occupied';
            }
            this.hideDetails();
        },
        hideDetails() {
            this.$emit('close');
        },
    },
}
</script>