<template>
    <div class="col bg-light shadow-bottom text-center">
        <h1>
            <router-link to="/" tag="a">
                <img src="/android-chrome-192x192.png" />
                Online RV / Stall Reservation
            </router-link>
        </h1>
    </div>
</template>

<script>
export default {
    name: "Header",
}
</script>

<style scoped>

</style>