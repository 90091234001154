<template>
    <b-modal ref="modal" id="resource-details" size="xl" style="max-width: auto;">
        <template slot="modal-header">
            <h5 class="modal-title">Select {{resourceTypePlural}}</h5>
            <button type="button" class="close" @click="hideDetails" aria-label="Close">&times;</button>
        </template>
        <div class="row" style="min-height: 80%">
            <div class="col-lg-10 col-xs">
                <div class="row">
                    <div class="col">
                        <div v-if="!selectedFacility">Select a {{facilityTypeSingular}} from the map below to view the available {{resourceTypePlural}}.</div>
                        <div v-if="selectedFacility">Select an available {{resourceTypeSingular}} from the map below. <a href="#" @click="() => selectedFacility = null">Back to {{facilityTypeSingular}} Selection</a></div>
                        <div class="svgContainer">
                            <component v-if="!selectedFacility" :is="{ template: map.svgContainer }">
                                <component v-for="facility in map.facilities" :key="facility.id" :is="{ template: `<g @click='clickHandler'>${facility.svgFragment}</g>`, props: { item: Object, clickHandler: {} } }" :item="facility" :clickHandler="() => selectFacility(facility)" />
                            </component>
                            <component v-if="selectedFacility" :is="{ template: selectedFacility.svgContainer }">
                                <component v-for="resource in selectedFacility.resources" :key="resource.id" :is="{ template: `<g @click='clickHandler'>${resource.svgFragment}</g>`, props: { item: Object, clickHandler: {} } }" :item="resource" :clickHandler="() => selectResource(resource)" />
                            </component>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-xs">
                <div class="row">
                    <div class="col text-center">
                        <div style="font-weight: bold">Selected {{resourceTypePlural}}</div>
                        <div v-if="!selectedResources.length" class="placeholder-empty">
                            None Selected
                        </div>
                        <div v-for="resource in selectedResources" :key="resource.id" class="widget widget-primary d-flex mb-1">
                            <span class="widget-item text-left flex-grow-1">{{resource.number}}</span>
                            <button type="button" class="btn btn-primary widget-item"><font-awesome-icon :icon="['far', 'trash-alt']" @click="() => selectResource(resource)" /></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template slot="modal-footer">
            <button type="button" class="btn btn-link" @click="hideDetails">Cancel</button>
            <button type="button" class="btn btn-primary" @click="saveSlot" :disabled="selectedResources.length > 0 ? null : 'disabled'">Reserve {{resourceTypePlural}}</button>
        </template>
    </b-modal>
</template>

<script>
import apiClient from "../apiClients/reflectionArenaClient";

export default {
    name: "SelectResources",
    props: {
        'visible': Boolean,
        'attractionId': String,
        'resourceTypeId': String,
        'reservationId': String,
        'facilityTypeSingular': String,
        'resourceTypeSingular': String,
        'resourceTypePlural': String,
    },
    mounted() {
        this.$root.$on('bv::modal::hidden', () => {
            this.$emit('close');
        });
    },
    watch: {
        async visible(newVal, oldVal) {
            if (!newVal && oldVal) {
                this.$refs['modal'].hide();
            }
            else if (newVal && !oldVal) {
                this.selectedFacility = null;
                this.map = await apiClient.getAttractionMap(this.attractionId, this.resourceTypeId, this.reservationId);
                this.selectedResources = this.map.facilities.map(x => x.resources).flat().filter(x => x.resourceTypeId.toLowerCase() === this.resourceTypeId.toLowerCase() && x.style === 'resource-reserved')
                .sort((a, b) => a.sortOrder < b.sortOrder ? -1 : b.sortOrder < a.sortOrder ? 1 : 0);
                this.$refs['modal'].show();
            }
        },
    },
    methods: {
        saveSlot() {
            this.$emit('addResources', this.selectedResources);
            this.hideDetails();
        },
        hideDetails() {
            this.$emit('close');
        },
        selectFacility(facility) {
            if (facility.style.indexOf('facility-muted') >= 0) {
                return;
            }
            this.selectedFacility = facility;
        },
        selectResource(resource) {
            console.log(resource);
            switch (resource.style) {
                case "resource-unavailable":
                    break;
                case "resource-available":
                    resource.style = "resource-reserved";
                    this.selectedResources.push(resource);
                    break;
                case "resource-reserved":
                    resource.style = "resource-available";
                    this.selectedResources.splice(this.selectedResources.indexOf(resource), 1);
                    break;
                default:
                    break;
            }
            this.selectedResources = this.selectedResources.sort((a,b) => {
                if (a.sortOrder > b.sortOrder) return 1;
                if (b.sortOrder > a.sortOrder) return -1;
                return 0;
            })
        },
    },
    data() {
        return {
            selectedFacility: null,
            map: {},
            selectedResources: [],
        };
    },
};
</script>